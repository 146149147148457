<template>
<div id="account">
  <b-field class="markdown-body">
    <help-account />
  </b-field>
  <b-tabs v-model="tabIndex" position="is-centered" type="is-boxed" size="is-medium">
    <b-tab-item label="处理玩家历史">
      <b-field grouped group-multiline  position="is-centered" class="box">
        <b-datepicker
          placeholder="开始日期"
          v-model="rangeDates"
          :min-date="minDate"
          :max-date="maxDate"
          :loading="isLoading"
          icon="calendar-today"
          range
          @input="getRecord">
        </b-datepicker>
      </b-field>
      <br>
      <b-table :data="items"
        paginated backend-pagination
        :total="total"
        :per-page="perPage"
        :current-page="currentPage"
        @page-change="onPageChange">
        <b-table-column #default="props" field="sagiuid" label="SAGIUID(LID)" width="100" numeric>
          {{ props.row.sagiuid }}
        </b-table-column>
        <b-table-column #default="props" field="headimg" label="头像" width="80">
          <figure class="image is-32x32">
            <img v-if="props.row.headimg" :src="props.row.headimg" :alt="props.row.nickname" />
            <span v-else>N/A</span>
          </figure>
        </b-table-column>
        <b-table-column #default="props" field="nickname" label="昵称" width="180">
          {{ props.row.nickname }}
        </b-table-column>
        <b-table-column #default="props" field="channel" label="渠道" width="80">
          {{ props.row.channel }}
        </b-table-column>
        <b-table-column #default="props" field="status" label="状态" width="120">
          {{ props.row.status }}
        </b-table-column>
        <b-table-column #default="props" field="openid" label="OPENID">
          {{ props.row.wxopenid }}
        </b-table-column>
        <b-table-column #default="props" field="createtime" label="创建时间" centered>
          {{ formatDateTime(props.row.createtime) }}
        </b-table-column>
        <b-table-column #default="props" field="historytime" label="处理时间" centered>
          {{ formatDateTime(props.row.historytime) }}
        </b-table-column>
        <b-table-column #default="props" field="note" label="处理原因">
          {{ props.row.note }}
        </b-table-column>
        <template #bottom-left>
          <b>总计：</b>{{ total }}
        </template>
      </b-table>
    </b-tab-item>
    <b-tab-item label="处理玩家">
      <b-field grouped group-multiline  position="is-centered" class="box">
        <nickname-searcher ref="nicknameSearcher" @search="onSearch" />
      </b-field>
      <template v-if="currentUser">
        <b-field horizontal label="操作" >
          <div class="buttons">
            <button class="button is-info" @click="onUserCacheGet(currentUser)">查看缓存</button>
            <button class="button is-warning" @click="onUserCacheClear(currentUser)">清空缓存</button>
            <button class="button is-danger" @click="onUserDelete(currentUser)">删除账号</button>
            <button class="button is-dark " @click="onUserCacheChange(currentUser)">修改缓存</button>
          </div>
        </b-field>
        <b-field horizontal label="SAGIUID">
            {{ currentUser.sagiuid }}
        </b-field>
        <b-field horizontal label="头像">
            <figure class="image is-32x32">
              <img v-if="currentUser.headimg" :src="currentUser.headimg" :alt="currentUser.nickname" />
              <span v-else>N/A</span>
            </figure>
        </b-field>
        <b-field horizontal label="昵称">
            {{ currentUser.nickname }}
        </b-field>
        <b-field horizontal label="渠道">
            {{ currentUser.channel }}
        </b-field>
        <b-field horizontal label="状态">
          <b-select v-model="userEditStatus">
            <option v-for="typ of statusTypes"
              :value="typ.status"
              :key="typ.status">
              {{typ.status}}-{{typ.name}}
            </option>
          </b-select>
          <b-input v-if="canSaveUser" placeholder="输入原因" v-model.trim="userEditNote" expanded />
          <button v-if="canSaveUser" class="button is-danger" @click="onUserEditStatus">保存状态</button>
        </b-field>
        <b-field horizontal label="OPENID">
            {{ currentUser.wxopenid }}
        </b-field>
        <b-field horizontal label="UNIONID">
            {{ currentUser.wxunionid }}
        </b-field>
        <b-field horizontal label="创建时间">
            {{ currentUser.createtime }}
        </b-field>
        <b-field horizontal label="更新时间">
            {{ currentUser.updatetime }}
        </b-field>
      </template>
    </b-tab-item>
  </b-tabs>
</div>
</template>

<script>
import config from '@/core/config'
import cache from '@/core/cache'

import NicknameSearcher from '@/components/NicknameSearcher'
import { fromNow } from '@/core/util'

import HelpAccount from '@/help/account.md'

export default {
  name: 'account',
  components: { NicknameSearcher, HelpAccount },
  mounted () {
    this.regional = cache.getR(true)
    this.getRecord()
  },
  computed: {
    canSaveUser () {
      return this.currentUser !== null && this.currentUser.status !== this.userEditStatus
    }
  },
  data () {
    const user = cache.getUser()
    return {
      perPage: config.PER_PAGE,
      currentPage: 1,
      total: 0,
      user: user,
      items: [],
      tabIndex: 0,
      channels: [],
      channel: null,
      regional: null,
      minDate: new Date(2018, 4, 12),
      maxDate: new Date(),
      rangeDates: [fromNow({ days: -30 }), new Date()],
      statusTypes: config.USER_STATUS_TYPES.filter(typ => typ.status !== -1),
      isLoading: false,
      // 若值为 0 则代表非编辑 status 状态
      userEditStatus: 0,
      userEditNote: null,
      currentUser: null,
      currentUserCache: ''
    }
  },
  methods: {
    async onUserCacheGet (user) {
      try {
        this.isLoading = true
        this.hub.showProgress(true)
        const rdata = await this.mjp.get2({
          url: '/cache/get/byadmin/',
          query: { sagiuid: user.sagiuid },
          mjpType: 'auth'
        })
        this.hub.showProgress(false)
        this.isLoading = false
        this.currentUserCache = rdata.value
        this.$buefy.dialog.alert({
          title: `${user.nickname}(${user.sagiuid})`,
          message: rdata.value,
          type: 'is-info',
          hasIcon: false
        })
      } catch (e) {
        this.isLoading = false
        this.hub.showProgress(false)
      }
    },
    onUserCacheClear (user) {
      this.$buefy.dialog.confirm({
        title: '确认清空玩家缓存？',
        message: '清空玩家缓存将导致玩家丢失所有的游戏记录，包括这个玩家邀请进入的玩家计数。<br><br>若这个玩家再次登录，之前保存的数据会全部消失，但 <strong>UID 不会</strong> 发生变化。',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            this.isLoading = true
            this.hub.showProgress(true)
            await this.mjp.get2({
              url: '/cache/clear/',
              query: { idtype: 'sagiuid', idvalue: user.sagiuid },
              mjpType: 'auth'
            })
            this.hub.showProgress(false)
            this.isLoading = false
            this.hub.alert(`清空玩家 ${user.nickname}(${user.sagiuid}) 成功！`, 5)
          } catch (e) {
            this.isLoading = false
            this.hub.showProgress(false)
          }
        }
      })
    },
    onUserDelete (user) {
      this.$buefy.dialog.confirm({
        title: '确认删除玩家账号？',
        message: '将从数据库中直接删除玩家的账号信息。<br><br>若这个玩家再次登录，将新建一个账号，玩家的 <strong>UID 会发生变化</strong>。',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            this.isLoading = true
            this.hub.showProgress(true)
            await this.mjp.post2({
              url: '/user/del/',
              query: { idtype: 'sagiuid', idvalue: user.sagiuid },
              mjpType: 'pa'
            })
            this.hub.showProgress(false)
            this.isLoading = false
            this.hub.alert(`已经删除玩家 ${user.nickname}(${user.sagiuid}) 的账号！`, 5)
            this.getRecord()
          } catch (e) {
            this.isLoading = false
            this.hub.showProgress(false)
          }
        }
      })
    },
    isJson (jsonStr) {
      // eslint-disable-next-line eqeqeq
      if (typeof jsonStr == 'string') {
        try {
          var obj = JSON.parse(jsonStr)
          // eslint-disable-next-line eqeqeq
          if (typeof obj == 'object' && obj) {
            return true
          } else {
            return false
          }
        } catch (e) {
          return false
        }
      }
      return false
    },
    async onUserCacheChange (user) {
      try {
        this.isLoading = true
        this.hub.showProgress(true)
        const rdata = await this.mjp.get2({
          url: '/cache/get/byadmin/',
          query: { sagiuid: user.sagiuid },
          mjpType: 'auth'
        })
        this.hub.showProgress(false)
        this.isLoading = false
        this.currentUserCache = rdata.value
        this.changeCache(user)
      } catch (e) {
        this.isLoading = false
        this.hub.showProgress(false)
      }
    },
    async changeCache (user) {
      this.$buefy.dialog.prompt({
        message: '修改玩家缓存信息',
        inputAttrs: {
          type: 'text',
          placeholder: '',
          value: JSON.stringify(this.currentUserCache)
        },
        confirmText: '修改',
        trapFocus: true,
        closeOnConfirm: false,
        onConfirm: async (value) => {
          if (this.isJson(value)) {
            try {
              this.isLoading = true
              this.hub.showProgress(true)
              const data = {
                idtype: 'sagiuid',
                idvalue: user.sagiuid,
                value: JSON.parse(value)
              }
              await this.mjp.post2({
                url: '/cache/change/',
                data,
                mjpType: 'auth'
              })
              this.hub.showProgress(false)
              this.isLoading = false
              this.hub.alert(`成功修改玩家 ${user.nickname}(${user.sagiuid}) 的账号！`, 5)
              this.getRecord()
            } catch (e) {
              this.isLoading = false
              this.hub.showProgress(false)
            }
          } else {
            setTimeout(() => {
              this.$buefy.toast.open('请检查玩家信息')
              close()
            }, 2000)
          }
        }
      })
    },
    async onUserEditStatus () {
      this.isLoading = true
      await this.mjp.post2({
        url: '/user/set/status/',
        query: { r: this.currentUser.r, idtype: 'sagiuid', idvalue: this.currentUser.sagiuid },
        data: { status: this.userEditStatus, note: this.userEditNote },
        mjpType: 'pa'
      })
      this.isLoading = false
      this.getRecord()
    },
    onSearch (users) {
      this.clear()
      this.currentUser = users[0]
      this.userEditStatus = this.currentUser.status
    },
    onPageChange (page) {
      this.currentPage = page
      this.getRecord(page)
    },
    async getRecord (page) {
      if (this.isLoading) {
        return
      }
      this.tabIndex = 0
      page = page || this.currentPage || 1
      const filterObj = {
        channel: JSON.stringify(this.channel),
        page,
        per_page: this.perPage,
        from_date: this.formatDate(this.rangeDates[0]),
        to_date: this.formatDate(this.rangeDates[1])
      }

      // 避免 getRecord 被多次调用
      this.isLoading = true
      const data = await this.mjp.get2({
        url: '/user/get/history/more/',
        query: filterObj,
        mjpType: 'pa'
      })
      this.isLoading = false
      this.items = data.users
      this.total = data.total
      this.perPage = data.per_page
      this.currentPage = data.page
    },
    clear () {
      this.currentUser = null
      this.userEditStatus = 0
      this.userEditNote = null
      this.total = 0
    }
  },
  watch: {
    tabIndex (to, from) {
      if (to === 0) {
        this.clear()
        this.getRecord()
      }
      if (to === 1) {
        this.clear()
        this.items = []
      }
    }
  }
}
</script>

<style>
</style>
